import { useRouter } from 'next/router'

import { paths } from '~/constants'

import { LoginForm } from '../LoginModal/LoginForm'
import { LoginPageWrapper } from '../LoginModal/LoginModalShards'

export const LoginPage = () => {
  const router = useRouter()

  return (
    <LoginPageWrapper>
      <LoginForm
        handleClose={() => {
          router.push(paths.home)
        }}
      />
    </LoginPageWrapper>
  )
}
